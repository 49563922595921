import type { BaseEventMetrics } from '@schibsted/pulse-news-media/lib/types/types';
import type { BlendPersonalization } from '../../../data-sources/frapi/types.js';
import type { PulseAttributes } from '../../../../../public-src/core/js/pulse/pulse-util.js';

type GenerateBlendMetricsReturn =
  | [
      {
        source: 'Curate';
        name: 'personalizedResults';
        key: string;
        value: number;
      },
      {
        source: 'Curate';
        name: 'removeImpressed';
        key: string;
        value: number;
      },
      {
        source: 'Curate';
        name: 'removeRead';
        key: string;
        value: number;
      },
    ]
  | [];

export const generateTeaserMetrics = (
  blendId: string,
  pulseMeta: PulseAttributes,
): BaseEventMetrics => {
  const hasPersonalizationScore = pulseMeta.hasPersonalizationScore;
  const seenCount = pulseMeta.seenCount;
  const hasBeenRead = pulseMeta.hasBeenRead;

  return [
    {
      source: 'Curate',
      name: 'personalizedResults',
      key: blendId,
      value: hasPersonalizationScore,
    },
    {
      source: 'Curate',
      name: 'removeImpressed',
      key: blendId,
      value: seenCount,
    },
    {
      source: 'Curate',
      name: 'removeRead',
      key: blendId,
      value: hasBeenRead,
    },
  ];
};

export const getTeaserMetricsObject = (
  blendId: string | undefined,
  pulseMeta: PulseAttributes,
): BaseEventMetrics | null => {
  return blendId &&
    pulseMeta.entityType === 'ARTICLE' &&
    /* check source to exclude teasers in widgets */
    pulseMeta.source?.includes('frontpage')
    ? generateTeaserMetrics(blendId, pulseMeta)
    : null;
};

export const generateBlendMetrics = (
  blendId: string | undefined,
  personalization: BlendPersonalization | undefined | null,
): GenerateBlendMetricsReturn => {
  if (!blendId) return [];

  return [
    {
      source: 'Curate',
      name: 'personalizedResults',
      key: blendId,
      value: personalization?.personalizedResults ?? 0,
    },
    {
      source: 'Curate',
      name: 'removeImpressed',
      key: blendId,
      value: personalization?.removeOnImpressed ?? 0,
    },
    {
      source: 'Curate',
      name: 'removeRead',
      key: blendId,
      value: personalization?.removeOnRead ?? 0,
    },
  ];
};
